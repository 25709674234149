import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api, getTicketTypeById, getTicketTypeInfosByTicketTypeId } from "ticketino-api-client";
import parse from "html-react-parser";

import Header from "./components/Header";
import Footer from "./components/Footer";

import icon_mail from "../images/payments/mail.svg"
import icon_printer from "../images/payments/printer.svg"
import icon_paypal from "../images/payments/paypal.svg"
import icon_mastercard from "../images/payments/mastercard.svg"
import icon_visa from "../images/payments/visa.svg"
import icon_amex from "../images/payments/amex.svg"
import icon_twint from "../images/payments/twint.svg"
import icon_postfinance from "../images/payments/postfinance.svg"
import icon_invoice from "../images/payments/invoice.svg"

const Checkout = () => {
    const [orderId] = useState(sessionStorage.getItem("orderId"));
    const [token] = useState(sessionStorage.getItem("token"));

    const [order, setOrder] = useState({});
    const [tickets, setTickets] = useState([]);
    const [payment, setPayment] = useState({
        paymentMethodId: 0,
        deliveryMethodId: 0,
        insurance: false
    })

    // react hook for navigation
    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // setting the base url of the npm package api calls
    api.defaults.baseURL = baseUrl;

    // fetching resources
    const [resources, setResources] = useState({});

    // fetching params
    const { language } = useParams();

    let languageId = 0;

    // changing languageId according to the url
    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            languageId = 0;
            break;
    }

    useEffect(() => {
        // gtm - addPaymentInfo
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'addPaymentInfo'
        });
    }, [])

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        api.defaults.headers.common['Authorization'] = "Bearer " + token;

        if (orderId) {
            loadOrder(orderId);
        }
    }, [orderId]); //gets called when an order is started

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.translation);
            })
            .catch((error) => console.error(error.response.data));
    };

    const loadOrder = async (orderId) => {
        try {
            // Order
            const updatedOrder = await getOrderByOrderId(orderId);

            const bookedTickets = updatedOrder.tickets;

            // Booked Tickets
            const updatedTickets = await Promise.all(
                bookedTickets.map(async (ticket) => {
                    const ticketType = await getTicketTypeById(ticket.ticketTypeId);

                    const info = await getTicketTypeInfosByTicketTypeId(ticket.ticketTypeId);
                    const ticketTypeInfo = info.find(info => info.languageId == languageId) ?? info[0];

                    return { ...ticket, ticketType: ticketType, info: ticketTypeInfo }
                })
            )

            let updatedPayment = { ...payment, deliveryMethodId: updatedOrder.deliveryMethodId, paymentMethodId: updatedOrder.paymentMethodId };

            updatedPayment.insurance = updatedOrder.totalInsuranceAmount > 0;

            setPayment(updatedPayment);
            setOrder(updatedOrder);
            setTickets(updatedTickets);
        }
        catch (error) {
            console.error(error);
        }
    }

    const getOrderByOrderId = async (orderId) => {
        try {
            const res = await axios.get(`${baseUrl}/ShopBasket/Order/${orderId}`);
            return res.data;
        }
        catch (error) {
            console.error(error);
        }
    }

    const changeDeliveryType = async (deliveryMethodId) => {
        await axios.put(
            `${baseUrl}/ShopBasket/Order/${orderId}/DeliveryMethod/${deliveryMethodId}`)
            .then(() => {
                loadOrder(orderId);
            })
            .catch((error) => {
                console.error(error.response.data);
            });
    };

    const changePaymentType = (paymentMethodId) => {
        axios
            .put(`${baseUrl}/ShopBasket/Order/${orderId}/PaymentType/${paymentMethodId}`)
            .then((res) => {
                loadOrder(orderId);
            })
            .catch((error) => {
                console.error(error.response.data);
            });
    };

    const startDatatrans = () => {
        var baseRequestUrl = `https://datatrans.ticketino.com/Datatrans/${orderId}/DigitalSignature`;

        axios
            .get(baseRequestUrl)
            .then((res) => {
                let datatransFormId = "datatrans-" + new Date().getTime();
                var form =
                    "<form className='datatrans' id='" + datatransFormId + "'></form>";

                let container = document.getElementById("datatrans-form-placeholder");

                container.innerHTML += form;

                let element = document.getElementById(datatransFormId);

                // merchantId for testing
                // element.setAttribute("data-merchant-id", "1100004624");
                element.setAttribute("data-merchant-id", res.data.merchantId);
                element.setAttribute("data-amount", res.data.amount);
                element.setAttribute("data-currency", res.data.currency);
                element.setAttribute("data-refno", res.data.referenceNumber);
                element.setAttribute("data-reqType", res.data.reqType);
                element.setAttribute(
                    "data-upp-return-target",
                    res.data.uppReturnTarget
                );
                element.setAttribute("data-paymentMethod", res.data.paymentMethod);
                element.setAttribute("data-sign", res.data.digitalSignature);

                let domain = "https://" + window.location.host;

                let successUrl = domain + `/form/redirect/${language}/success/${orderId}`;
                let errorUrl = domain + `/form/redirect/${language}/error/${orderId}`;
                let cancelUrl = domain + `/form/redirect/${language}/cancel/${orderId}`;

                element.setAttribute("data-success-url", successUrl);
                element.setAttribute("data-error-url", errorUrl);
                element.setAttribute("data-cancel-url", cancelUrl);

                for (const key in res.data.userInfo) {
                    element.setAttribute(key, res.data.userInfo[key]);
                }

                for (const key in res.data.merchantSpecificParameters) {
                    element.setAttribute(key, res.data.merchantSpecificParameters[key]);
                }

                // start datatrans -> call the payment form
                window.Datatrans.startPayment({
                    form: "#" + datatransFormId,
                });
            })
            .catch((error) => {
                alert(error.response.data);
            });
    };

    const onSubmit = async () => {
        if (order?.totalPrice > 0) {
            let dataTransProviders = [1, 7, 8, 9, 10, 14];

            let datatransProvider = dataTransProviders.findIndex((d) => d === payment.paymentMethodId) !== -1

            if (datatransProvider) {
                startDatatrans();
            }
        } else {
            changePaymentTypeToFree();
        }
    }

    const setOrderInsurance = async () => {
        await axios.put(`${baseUrl}/ShopBasket/Order/${orderId}/Insurance`)
            .then(() => {
                loadOrder(orderId);
            }).catch((error) => {
                console.error(error);
            })
    }

    const removeOrderInsurance = async () => {
        await axios.delete(`${baseUrl}/ShopBasket/Order/${orderId}/Insurance`)
            .then(() => {
                loadOrder(orderId);
            }).catch((error) => {
                console.error(error);
            })
    }

    const confirmShopbasketByOrderId = async (orderId) => {
        try {
            const res = await axios.put(`${baseUrl}/ShopBasket/Order/${orderId}/Confirm`);
            return res.data;
        } catch (error) {
            console.error(error)
        }
    };

    const changePaymentTypeToFree = () => {
        axios
            .put(`${baseUrl}/ShopBasket/Order/${orderId}/PaymentType/5`)
            .then(async () => {
                await confirmShopbasketByOrderId(orderId);
                navigate(`/${language}/confirmation/${orderId}`);
            });
    }

    const mapTickets = () => {
        let ticketsSorted = [];

        if (tickets && tickets.length > 0) {
            ticketsSorted = [...tickets].sort((a, b) => a.id - b.id);
        }

        return (
            tickets &&
            tickets.length > 0 &&
            ticketsSorted.map((tt, index) => (
                <div key={index}>
                    <div className="row pt-2 pb-2">
                        <div className="col-md-10 col-8 p-0">
                            <label className="fs-6">
                                {tt.info?.name}
                            </label>
                        </div>
                        <div className="col-md-2 col-4 text-end p-0">
                            <label>
                                {tt.ticketType?.currency} {tt.price}.-
                            </label>
                        </div>
                    </div>
                </div>
            ))
        );
    }

    const mapExtraCosts = () => {
        return (
            <div>
                <div className="row pt-2 pb-2">
                    <div className="col-md-10 col-8 p-0">
                        <label className="fs-6">
                            {resources.DeliveryCosts}
                        </label>
                    </div>
                    <div className="col-md-2 col-4 text-end p-0">
                        <label>
                            {order.currency + " " + order?.deliverPaymentFee.toFixed(2)}
                        </label>
                    </div>
                </div>
            </div>
        )
    }

    const mapInsurance = () => {
        return (
            <div>
                <div className="row pt-2 pb-2">
                    <div className="col-md-10 col-8 p-0">
                        <label className="fs-6">
                            {resources.TicketInsurance}
                        </label>
                    </div>
                    <div className="col-md-2 col-4 text-end p-0">
                        <label>
                            {order.currency + " " + order.totalInsuranceAmount.toFixed(2)}
                        </label>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            <div id="datatrans-form-placeholder"></div>
            <Header />
            <div className="container mt-4 page-container-smaller">
                <>
                    <div>
                        <div className="row">
                            <div className="col-md-12 mb-1">
                                <p className="fs-5 fw-bold">{resources.DeliveryOption}</p>
                            </div>
                        </div>
                        <div className="mb-4">
                            <div className="form-check mb-3">
                                <input
                                    className="form-check-input me-2"
                                    type="radio"
                                    name="flexRadioDefault1"
                                    checked={payment.deliveryMethodId === 1 ? true : false}
                                    onClick={() => changeDeliveryType(1)}
                                    id="printAtHome"
                                />
                                <label
                                    className="form-check-label fs-6"
                                    htmlFor="printAtHome"
                                >
                                    <img htmlFor="mail" src={icon_mail} className="delivery_icon" alt="Mail Icon" />
                                    {resources.PrintAtHome}
                                    <p className="pt-1 m-0 text-secondary delivery-description">
                                        {resources.PrintAtHomeDescription}
                                    </p>
                                </label>
                            </div>
                        </div>

                        {
                            order.totalPrice > 0 &&
                            <>
                                <div className="row">
                                    <div className="col-md-12 mb-1">
                                        <p className="fs-5 fw-bold">{resources.PaymentOption}</p>
                                    </div>
                                </div>
                                <div className="mb-5">
                                                                        <div className="form-check mb-3">
                                        <input
                                            className="form-check-input me-2"
                                            type="radio"
                                            name="flexRadioDefault"
                                            checked={payment.paymentMethodId === 1 ? true : false}
                                            onClick={() => changePaymentType(1)}
                                            id="mastercard"
                                        />
                                        <label
                                            className="form-check-label payment-names fs-6"
                                            htmlFor="mastercard"
                                        >
                                            <img src={icon_mastercard} className="payment_icon" alt="Mastercard Icon" />
                                            {resources.MasterCard}
                                            <p className="pt-1 m-0 text-secondary payment-description">
                                                {resources.MasterCardDesc}
                                            </p>
                                        </label>
                                    </div>
                                    <div className="form-check mb-3">
                                        <input
                                            className="form-check-input me-2"
                                            type="radio"
                                            name="flexRadioDefault"
                                            checked={payment.paymentMethodId === 7 ? true : false}
                                            onClick={() => changePaymentType(7)}
                                            id="visa"
                                        />
                                        <label
                                            className="form-check-label payment-names fs-6"
                                            htmlFor="visa"
                                        >
                                            <img src={icon_visa} className="payment_icon" alt="Visa Icon" />
                                            {resources.Visa}
                                            <p className="pt-1 m-0 text-secondary payment-description">
                                                {resources.VisaDesc}
                                            </p>
                                        </label>
                                    </div>
                                    <div className="form-check mb-3">
                                        <input
                                            className="form-check-input me-2"
                                            type="radio"
                                            name="flexRadioDefault"
                                            checked={payment.paymentMethodId === 8 ? true : false}
                                            onClick={() => changePaymentType(8)}
                                            id="amex"
                                        />
                                        <label
                                            className="form-check-label payment-names fs-6"
                                            htmlFor="amex"
                                        >
                                            <img src={icon_amex} className="payment_icon" alt="Amex Icon" />
                                            {resources.AmericanExpress}
                                            <p className="pt-1 m-0 text-secondary payment-description">
                                                {resources.AmericanExpressDesc}
                                            </p>
                                        </label>
                                    </div>
                                    <div className="form-check mb-3">
                                        <input
                                            className="form-check-input me-2"
                                            type="radio"
                                            name="paypal"
                                            checked={payment.paymentMethodId === 10 ? true : false}
                                            onClick={() => changePaymentType(10)}
                                            id="paypal"
                                        />

                                        <label
                                            className="form-check-label payment-names fs-6"
                                            htmlFor="paypal"
                                        >
                                            <img htmlFor="paypal" src={icon_paypal} className="payment_icon" alt="Paypal Icon" />
                                            {resources.PayPal}
                                            <p className="pt-1 m-0 text-secondary payment-description">
                                                {resources.PayPalDesc}
                                            </p>
                                        </label>
                                    </div>
                                    <div className="form-check mb-3">
                                        <input
                                            className="form-check-input me-2"
                                            type="radio"
                                            name="flexRadioDefault"
                                            checked={payment.paymentMethodId === 9 ? true : false}
                                            onClick={() => changePaymentType(9)}
                                            id="postfinance"
                                        />
                                        <label
                                            className="form-check-label payment-names fs-6"
                                            htmlFor="postfinance"
                                        >
                                            <img src={icon_postfinance} className="payment_icon" alt="Postfinance Icon" />
                                            {resources.PostFinance}
                                            <p className="pt-1 m-0 text-secondary payment-description">
                                                {resources.PostFinanceDesc}
                                            </p>
                                        </label>
                                    </div>
                                    <div className="form-check mb-3">
                                        <input
                                            className="form-check-input me-2"
                                            type="radio"
                                            name="flexRadioDefault"
                                            checked={payment.paymentMethodId === 14 ? true : false}
                                            onClick={() => changePaymentType(14)}
                                            id="twint"
                                        />
                                        <label
                                            className="form-check-label payment-names fs-6"
                                            htmlFor="twint"
                                        >
                                            <img src={icon_twint} className="payment_icon" alt="Twint Icon" />
                                            {resources.Twint}
                                            <p className="pt-1 m-0 text-secondary payment-description">
                                                {resources.TwintDesc}
                                            </p>
                                        </label>
                                    </div>
                                </div>
                            </>
                        }

                        {/*{order.totalPrice > 0 &&*/}
                        {/*    <div className="row">*/}
                        {/*        <div className="col-md-12 mb-3">*/}
                        {/*            <p className="fs-5 fw-bold mb-3">{resources.TicketInsurance}</p><div className="form-check mb-4">*/}
                        {/*                <input*/}
                        {/*                    className="form-check-input me-3 "*/}
                        {/*                    type="checkbox"*/}
                        {/*                    checked={payment.insurance}*/}
                        {/*                    onChange={(e) => {*/}
                        {/*                        if (e.target.checked) {*/}
                        {/*                            setOrderInsurance();*/}
                        {/*                        } else {*/}
                        {/*                            removeOrderInsurance();*/}
                        {/*                        }*/}
                        {/*                    }}*/}
                        {/*                />*/}
                        {/*                <label*/}
                        {/*                    className="col-11 form-check-label fs-6"*/}
                        {/*                    for="inlineCheckbox1"*/}
                        {/*                >*/}
                        {/*                    {parse(resources.TicketInsuranceDesc ?? "")}*/}
                        {/*                </label>*/}
                        {/*            </div>*/}

                        {/*        </div>*/}
                        {/*    </div>}*/}

                        <div className="row">
                            <div className="col-md-12 mb-1">
                                <p className="fs-5 fw-bold mb-3">{resources.Summary}</p>
                                <div className="container">
                                    {mapTickets()}
                                    {payment.insurance && mapInsurance()}
                                    {order.deliverPaymentFee > 0 && mapExtraCosts()}
                                    <hr className="custom-hr"></hr>
                                    <div className="row mb-3">
                                        <div className="col-md-10 col-8 p-0">
                                            <label className="fs-6 fw-bold">
                                                {resources.Total}
                                            </label>
                                        </div>
                                        <div className="col-md-2 col-4 text-end p-0">
                                            <label className="fw-bold">
                                                {order.currency} {order.totalPrice?.toFixed(2)}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/*<div>*/}
                        {/*    <p className="mb-4">{parse(resources.AGB ?? "")}</p>*/}
                        {/*</div>*/}
                        <div className="row mt-4 mb-4">
                            <div className=" col-6 text-start">
                                <button className="button" onClick={() => navigate(-1)}>
                                    {resources?.Back}
                                </button>
                            </div>
                            <div className="col-6 text-end">
                                <button className="button" onClick={onSubmit}>{resources.Buy}</button>
                            </div>
                        </div>
                    </div>
                </>
            </div>
            <Footer language={language} />
        </div>
    )
}

export default Checkout