import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    api, getEventById, getEventInfosByEventId, getTicketTypesByEventId, getTicketTypeInfosByTicketTypeId,
    getTicketTypeNumberOfAvailableTickets
} from "ticketino-api-client";
import axios from "axios";

import imgTicketLocation from "../images/ticket_location_big.png";
import imgTicketLocationMasterclass from "../images/ticket_location_masterclass_big.png";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Loading from "./components/Loading";


const Tickets = () => {

    const [orderId, setOrderId] = useState();

    const [eventId, setEventId] = useState([]);
    const [eventGroupId, setEventGroupId] = useState([]);
    const [posId, setPosId] = useState();

    const [events, setEvents] = useState([]);

    const [ticketTypes, setTicketTypes] = useState([]);
    const [selectedTicketTypes] = useState([]);
    const [moreInfo, setMoreInfo] = useState({});

    const [disabled, setDisabled] = useState(true);
    const [loading, setLoading] = useState(true);
    const [loaded, setLoaded] = useState(false);

    const [promotioncode, setPromotioncode] = useState("");
    const [promotioncodeAdded, setPromotioncodeAdded] = useState("");
    const [promotionError, setPromotionError] = useState("");

    const [unlockedTicketTypes, setUnlockedTicketTypes] = useState([]);
    const [isTicketTypesUnlocked, setIsTicketTypesUnlocked] = useState(false);


    const [isMasterclassTickets, setIsMasterclassTickets] = useState(false);

    // react hook for navigation
    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // setting the base url of the npm package api calls
    api.defaults.baseURL = baseUrl;

    // fetching resources
    const [resources, setResources] = useState({});

    // fetching params
    const { language, eventType } = useParams();

    let languageId = 0;

    // changing languageId according to the url
    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            languageId = 0;
            break;
    }

    useEffect(() => {
        if (eventType === "exhibition-masterclass") setIsMasterclassTickets(true);

        loadToken();

        // clear the addedPromocodes in the session
        let array = [];
        sessionStorage.setItem("AddedPromotions", JSON.stringify(array));
    }, []); //only on first page load

    useEffect(() => {
        requestResources();

        // so it wont get called on first load
        if (loaded) {
            startOrder(posId, eventId);
        }
    }, [language]); //everytime language is changed

    useEffect(() => {
        if (orderId) {
            loadEvent(eventId, eventGroupId, orderId)
        }
    }, [orderId]); //gets called when an order is started

    const loadToken = async () => {
        await axios.get("form/token").then((res) => {
            axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;
            api.defaults.headers.common['Authorization'] = "Bearer " + res.data;

            sessionStorage.setItem("token", res.data);

            requestFormSettings();
        });
    };

    const requestFormSettings = async () => {
        await axios.get(`form/formsettings`).then((res) => {
            // setting the posID and eventID
            setPosId(res.data.posId);
            setEventId(res.data.eventIdExhibition);
            setEventGroupId(res.data.eventGroupIdMasterclass);
            startOrder(res.data.posId, res.data.eventIdExhibition);
        });
    };

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.translation);
            })
            .catch((error) => console.error(error.response.data));
    };

    const startOrder = async (posId, eventId) => {
        let order = {
            affiliateShopId: null,
            currency: "CHF",
            tenantId: 1,
            languageId: languageId,
            pointOfSaleId: posId,
            abbreviation: "",
            paymentType: 10,
        };

        await axios
            .post(`${baseUrl}/ShopBasket/Order`, order)
            .then((res) => {
                sessionStorage.setItem("orderId", res.data.id);
                setOrderId(res.data.id);
                setLoaded(true);
            })
            .catch((error) => console.error(error.response.data));
    };

    const loadEvent = async (eventId, eventGroupId, orderId) => {
        try {
            setLoading(true);

            let theEvents = [];

            if (isMasterclassTickets) {
                const eventGroupEvents = await getEventsByEventGroupId(eventGroupId);

                if (eventGroupEvents && eventGroupEvents.length > 0) {
                    theEvents = [...theEvents, ...eventGroupEvents];
                }
            } else {
                const event = await getEventById(eventId);

                if (event) {
                    theEvents = [...theEvents, event];
                }
            }

            let updatedEvents = [];
            let allUpdatedTicketTypes = [];


            for (const event of theEvents) {
                const infos = await getEventInfosByEventId(event.id);

                let eventInfo = infos.eventInfos.find(info => info.languageId === languageId);

                // If eventInfo is not found, fallback to defaultLanguageId (0)
                if (!eventInfo || Object.keys(eventInfo).length === 0) {
                    eventInfo = infos.eventInfos.find(info => info.languageId === 0);
                }

                const updatedEventInfo = { ...event, info: eventInfo };

                const ticketTypes = await getTicketTypesByEventId(event.id);

                const updatedTicketTypes = await Promise.all(
                    ticketTypes.map(async (ticketType) => {
                        const info = await getTicketTypeInfosByTicketTypeId(ticketType.id);

                        const ticketTypeInfo = info.find(info => info.languageId === languageId) ?? info[0];

                        const ticketTypeAvailability = await getTicketTypeNumberOfAvailableTickets(ticketType.id, orderId);

                        return { ...ticketType, info: ticketTypeInfo, availability: ticketTypeAvailability };
                    })
                );

                updatedEvents.push(updatedEventInfo);
                allUpdatedTicketTypes = [...allUpdatedTicketTypes, ...updatedTicketTypes];
            }

            setEvents(updatedEvents);
            setTicketTypes(allUpdatedTicketTypes);
            setLoading(false);

        } catch (error) {
            console.error(error);
        }
    }

    const getEventsByEventGroupId = async (eventGroupId) => {
        try {
            const res = await axios.get(`${baseUrl}/EventGroup/Events?eventGroupId=${eventGroupId}&languageCode=${language != "de" || language != "fr" || language != "it" || language != "en" ? "de" : language}`);
            return res.data?.events;
        } catch (error) {
            console.error(error);
        }
    }

    const applyPromotionCodeToOrder = async (orderId, code) => {
        try {
            const response = await axios.put(`${baseUrl}/ShopBasket/Order/${orderId}/PromotionCode/${code}`);
            return response.data;
        } catch (error) {
            console.error(error.response.data);
            setPromotionError(resources.detailPage?.errorMessage);
        }
    }

    const deletePromotionCodeFromOrder = async (orderId, code) => {
        try {
            const response = await axios.delete(`${baseUrl}/ShopBasket/Order/${orderId}/PromotionCode/${code}`);
            return response.data;
        } catch (error) {
            console.error(error.response.data);
            setPromotionError(resources.detailPage?.errorMessage);
        }
    }

    const getTicketTypeAvailability = async (ticketTypeId, orderId, promotionCode) => {
        try {
            const response = await axios.get(`${baseUrl}/TicketType/${ticketTypeId}/Availability?orderId=${orderId}&promotionCode=${promotionCode}`);
            return response.data;
        } catch (error) {
            console.error(error.response.data);
            setPromotionError(resources.detailPage?.errorMessage);
        }
    }

    const addPromotionToOrder = async () => {
        setPromotionError("");
        setLoading(true);

        const updatedUnlockedTicketTypes = await applyPromotionCodeToOrder(orderId, promotioncode);
        let updatedTicketTypes = [];

        updatedTicketTypes = await Promise.all(ticketTypes.map(async (ticketType) => {
            if (updatedUnlockedTicketTypes?.unlockedTicketTypes?.includes(ticketType.id)) {
                const unlockedTicketTypeAvailability = await getTicketTypeAvailability(ticketType.id, orderId, promotioncode);
                ticketType.availability = unlockedTicketTypeAvailability;
            }
            return ticketType;
        }))

        sessionStorage.setItem("addedPromotion", promotioncode);
        setPromotioncodeAdded(promotioncode);

        setTicketTypes(updatedTicketTypes)
        setUnlockedTicketTypes(updatedUnlockedTicketTypes?.unlockedTicketTypes ?? []);
        setIsTicketTypesUnlocked(true);
        setLoading(false);
    }

    const removePromotionFromOrder = async () => {
        setPromotionError("");
        setLoading(true);

        await deletePromotionCodeFromOrder(orderId, promotioncode);
        await loadEvent(eventId, eventGroupId, orderId);

        setUnlockedTicketTypes([]);
        setIsTicketTypesUnlocked(false);
        setLoading(false);
    }

    const addTicketToBasket = async () => {
        let addTicketTypes = [];

        addTicketTypes = selectedTicketTypes.filter((stt) => stt.quantity !== "0");

        addTicketTypes = addTicketTypes.map((att) => {
            if (unlockedTicketTypes.includes(att.ticketTypeId)) {
                att.promotionCode = promotioncodeAdded;
            }

            return att;
        })

        await axios
            .post(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, {
                ticketsToAdd: addTicketTypes,
            })
            .then(() => {
                // gtm - addToCart
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'addToCart'
                });

                navigate(`/${language}/address`);
            })
            .catch((error) => {
                console.error(error.response.data);
            });
    };

    const onSubmit = async () => {
        await addTicketToBasket();
    };

    const onTicketTypeChange = (e, ticketTypeId) => {
        const obj = {
            ticketTypeId: ticketTypeId,
            quantity: e.target.value,
        };

        const existingTicketType = selectedTicketTypes.find(
            (t) => t.ticketTypeId === ticketTypeId
        );

        if (existingTicketType) {
            existingTicketType.quantity = e.target.value;
        } else {
            selectedTicketTypes.push(obj);
        }

        // calcualate quantity and validate
        let quantity = 0;

        selectedTicketTypes.map(s => {
            quantity += s.quantity * 1;
        });

        if (quantity > 0) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    };

    const promotionCodeDiv = () => {
        return (
            <div className="row">
                <div className="col-md-8 col-12 mb-3">
                    <input className="form-control" value={promotioncode} onChange={(e) => setPromotioncode(e.target.value)} />
                </div>
                <div className="col-md-4 col-12 mb-3 row text-end pe-0">
                    <div className="col"><button className="btn button col-12" onClick={addPromotionToOrder}>{resources?.PromotionRedeem}</button></div>
                    <div className="col pe-0"><button className="btn button col-12" onClick={removePromotionFromOrder}>{resources?.PromotionRemove}</button></div>
                </div>
            </div>
        )
    }

    const mapEvents = () => {
        return (
            events &&
            events.map((event, index) => (
                <div key={index} className="event-section">
                    <h3>{event.info?.name}</h3>
                    <p>{event.info?.description}</p>
                    {/* You can now render each event with its ticket types */}
                    {mapTicketTypes(event.id)}
                </div>
            ))
        );
    };

    const mapTicketTypes = (eventId) => {
        let ticketTypesSorted = [];

        if (ticketTypes && ticketTypes.length > 0) {
            ticketTypesSorted = [...ticketTypes]
                .filter(tt => tt.eventId === eventId)
                .sort((a, b) => a.sortOrder - b.sortOrder);
        }

        return (
            <div>
                {ticketTypes &&
                    ticketTypes.length > 0 &&
                    ticketTypesSorted.map((tt, index) => (
                        <div key={index}>
                            {
                                (tt.availability?.status === 0) &&
                                (
                                    <div className="row pt-2 pb-2">
                                        <div className="col-md-7 col-5">
                                            <label className="fs-6">
                                                {tt.info?.name}
                                            </label>
                                            {
                                                tt.info?.description && (
                                                    <div className="description">
                                                        <span onClick={() => setMoreInfo({ [tt.id]: !moreInfo[tt.id] })}>{resources.OtherInfos} <i className={"bi bi-chevron-" + (moreInfo[tt.id] ? "down" : "up")}></i></span><br></br>
                                                        {moreInfo[tt.id] && tt.info?.description}
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="col-md-3 col-4 text-center">
                                            <label>
                                                {tt.currency} {tt.price}.-
                                            </label>
                                        </div>
                                        <div className="col-md-2 col-3">
                                            <select
                                                className="form-select"
                                                onChange={(e) => onTicketTypeChange(e, tt.id)}
                                            >
                                                {Array.from(
                                                    Array(
                                                        tt.availability?.availableTickets + 1
                                                    ),
                                                    (e, i) => {
                                                        return (
                                                            <option key={i} value={i}>
                                                                {i}
                                                            </option>
                                                        );
                                                    }
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                )}
                        </div>
                    ))}
            </div>
            
        );
    };

    return (
        <div>
            <Header language={language} />
            <div className="container page-container">
                <div className="row">
                    <div className="col-md-12">
                        {isMasterclassTickets ? (
                            <img src={imgTicketLocationMasterclass} alt="" className="img-fluid" />
                        ) : (
                            <img src={imgTicketLocation} alt="" className="img-fluid" />
                        )}
                    </div>
                </div>
                <div className="text-center mt-4">
                    {isMasterclassTickets ? (
                        <p className="subtitle mb-2">ZURICH WINE FESTIVAL 2024 - MASTERCLASS TICKETS</p>
                    ) : (
                        <p className="subtitle mb-2">ZURICH WINE FESTIVAL 2024 - EXHIBITION TICKETS</p>
                    )}
                </div>
                <div className="mt-4">
                    {!loading ? mapEvents() : <Loading bgColor="#fff" color="#ee4666" />}
                </div>
                {/*<div>*/}
                {/*    <p dangerouslySetInnerHTML={{ __html: resources?.HomePromocodeContact }}></p>*/}
                {/*</div>*/}
                {/*<div className="mt-4">*/}
                {/*    <p className="fs-5 fw-bold mb-2">{resources?.PromotionCode}</p>*/}
                {/*    <hr></hr>*/}
                {/*    <p className="fs-6">{resources?.PromotionDescription}</p>*/}
                {/*    {promotionCodeDiv()}*/}
                {/*    <p className="fs-6 mb-4">{resources?.PromotionDescription2}</p>*/}
                {/*</div>*/}
                <div className="row mt-4 mb-4">
                    <div className=" col-6 text-start">
                        <button className="button" onClick={() => navigate(-1)}>
                            {resources?.Back}
                        </button>
                    </div>
                    <div className="col-6 text-end">
                        <button className="btn button" disabled={disabled} onClick={() => onSubmit()}>
                            {resources?.Next}
                        </button>
                    </div>
                </div>
            </div>
            <Footer language={language} />
        </div>
    )
}

export default Tickets