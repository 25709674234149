import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "ticketino-api-client";
import axios from "axios";

import imgTicketLocation from "../images/ticket_location.png";
import imgTicketLocationMasterclass from "../images/ticket_location_masterclass.png";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Loading from "./components/Loading";

const Home = () => {

    const [eventId, setEventId] = useState([]);
    const [posId, setPosId] = useState();
    
    // react hook for navigation
    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // setting the base url of the npm package api calls
    api.defaults.baseURL = baseUrl;

    // fetching resources
    const [resources, setResources] = useState({});

    // fetching params
    const { language } = useParams();

    let languageId = 0;

    // changing languageId according to the url
    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            languageId = 0;
            break;
    }

    useEffect(() => {
        loadToken();
    }, []); //only on first page load

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    const loadToken = async () => {
        await axios.get("form/token").then((res) => {
            axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;
            api.defaults.headers.common['Authorization'] = "Bearer " + res.data;

            sessionStorage.setItem("token", res.data);

            requestFormSettings();
        });
    };

    const requestFormSettings = async () => {
        await axios.get(`form/formsettings`).then((res) => {
            // setting the posID and eventID
            setPosId(res.data.posId);
            setEventId(res.data.eventId);
        });
    };

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.translation);
            })
            .catch((error) => console.error(error.response.data));
    };

    const onSubmit = async () => {
        alert("hi");
    };

    return (
        <div>
            <Header language={language} />
            <div className="container page-container">
                <div>
                    <span className="subtitle">Location Exhibition</span>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div>
                            <img src={imgTicketLocation} alt="" className="img-fluid" />
                        </div>
                        <div>
                            <span className="grey">{resources?.HomePapiersaal}</span>
                        </div>
                    </div>
                    <div className="col-md-6 text-center">
                        <p className="subtitle">17 -19 OCTOBER 2024</p>
                        <p>This is not another old-fashioned wine tasting. Enjoy over 350 wines from around the world while learning useful insights from our industry experts.</p>
                        <div>
                            <a href="https://zurichwinefestival.ch/tickets_masterclasses.html" className="btn button">
                                <span>Learn more</span>
                            </a>
                        </div>
                        <div className="mt-3">
                            <button className="btn button" onClick={() => { navigate(`/${language}/tickets/exhibition`) }}>
                                <span>Tickets</span>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="mt-5">
                    <span className="subtitle">Location Masterclasses</span>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div>
                            <img src={imgTicketLocationMasterclass} alt="" className="img-fluid" />
                        </div>
                        <div className="d-flex justify-content-between">
                            <span className="grey">{resources?.HomePapiersaal}</span>
                            <span className="grey">{resources?.HomeAdagio}</span>
                        </div>
                    </div>
                    <div className="col-md-6 text-center">
                        <p className="subtitle">14 -16 OCTOBER 2024</p>
                        <p>We have created unique experiences for you to taste and learn about exciting wines. Choose the experience that suits you best.</p>
                        <div>
                            <a href="https://zurichwinefestival.ch/tickets_masterclasses.html" className="btn button">
                                <span>Learn more</span>
                            </a>
                        </div>
                        <div className="mt-3">
                            <button className="btn button" onClick={() => { navigate(`/${language}/tickets/exhibition-masterclass`) }}>
                                <span>Tickets</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer language={language} />
        </div>
    )
}

export default Home