import React, { useEffect, useState } from 'react'
import logo from "../../images/logo_white.png"
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

const Header = () => {
    const [resources, setResources] = useState({});

    let navigate = useNavigate();

    const { language } = useParams();

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.header);
            })
            .catch((error) => console.error(error.response.data));
    };

    return (
        <>
            <div className="header-background">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <a href="https://zurichwinefestival.ch">
                                <img src={logo} alt="" className="img-fluid" />
                            </a>
                        </div>
                    </div>
                </div>
                
            </div>
            <div className="mt-4 mb-5 text-center">
                {/*<span className="title">14 - 20 OCTOBER 2024</span>*/}
            </div>
        </>
    )
}

export default Header