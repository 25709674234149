import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import ScrollToTop from './views/components/ScrollToTop';

import Home from "./views/Home";
import Tickets from "./views/Step10_Tickets";
import Address from "./views/Step20_Address";
import Checkout from "./views/Step40_Checkout";
import Confirmation from "./views/Step50_Confirmation";

// Fallback page
import PageNotFound from "./views/components/PageNotFound";

import "./css/style.css";

function App() {
  return (
      <BrowserRouter>
          <ScrollToTop />
        <Routes>
        <Route path="*" element={<PageNotFound />} />
        <Route path="/" element={<Navigate to="/en/home" replace />} />
        <Route path="/:language/home" element={<Home />} />
        <Route path="/:language/tickets/:eventType" element={<Tickets />} />
        <Route path="/:language/address" element={<Address />} />
        <Route path="/:language/checkout" element={<Checkout />} />
        <Route path="/:language/confirmation/:orderId" element={<Confirmation />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
