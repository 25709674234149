import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

import "../../css/footer.css"

const Footer = (props) => {
    const [resources, setResources] = useState({});

    const { language } = useParams();

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.translation);
            })
            .catch((error) => console.error(error.response.data));
    };

    return (
        <footer className="footer-background py-4">
            <div className="container">
                <div className="row f-14">
                    <div className="col-md-5">
                        <div className="footer-title">ZURICH WINE FESTIVAL</div>
                        <p className="grey">For any question please contact us at: <br /><a href="mailto:info@zurichwinefestival.ch">info@zurichwinefestival.ch</a></p>
                    </div>
                    <div className="col-md-7">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="footer-title">ZWF 2024</div>
                                <ul className="list-unstyled">
                                    <li><a href="https://zurichwinefestival.ch/program.html">Programm & Exhibitors</a></li>
                                    <li><a href="https://zurichwinefestival.ch/tickets_masterclasses.html">Tickets & Masterclasses</a></li>
                                    <li><a href="https://zurichwinefestival.ch/industry_operators.html">Industry & Operators</a></li>
                                    <li><a href="https://zurichwinefestival.ch/sustainability.html">Sustainability</a></li>
                                </ul>
                            </div>
                            <div className="col-md-4">
                                <div className="footer-title">PRESS</div>
                                <ul className="list-unstyled">
                                    <li><a href="https://zurichwinefestival.ch/press.html">Logo & Media</a></li>
                                    <li><a href="https://zurichwinefestival.ch/press.html">Press Releases</a></li>
                                </ul>
                            </div>
                            <div className="col-md-4">
                                <div className="footer-title">SUPPORT</div>
                                <ul className="list-unstyled">
                                    <li><a href="https://zurichwinefestival.ch/index.html#">FAQ</a></li>
                                    <li><a href="mailto:info@zurichwinefestival.ch">Contact Us</a></li>
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>
                
            </div>
            <div className="copyright text-center mt-4">
                {resources.FooterCopyright}
            </div>
        </footer>
    );
};

export default Footer;
